<template>
    <v-form>
        <v-stepper v-model="step" class="elevation-0">
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                step="1"
              >
                Тип создания задания
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="step > 2"
                step="2"
              >
                Создание задания
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                  <!-- Code check form -->
                  <div v-if="step === 1">
                      <v-radio-group v-model="form.type">
                        <v-radio
                          v-for="type in types"
                          :key="type.value"
                          :label="type.text"
                          :value="type.value"
                        ></v-radio>
                      </v-radio-group>

                      <v-divider class="my-4" />

                      <v-btn dark :color="$const.color.primary" :loading="waiting" @click.prevent.stop="submitStep">
                           {{ buttonLabel }}
                      </v-btn>
                  </div>
                 <!-- END Code check form -->
              </v-stepper-content>

              <v-stepper-content step="2">
                  <!-- Code activation form -->
                  <div v-if="step === 2">
                      <!-- Form here -->
                      <div v-if="form.type === 'plain'">
                        <v-textarea
                          v-model="$v.form.json.$model"
                          :error-messages="getErrors('form.json')"
                          label="Вставьте JSON" 
                          name="json" 
                          :color="$const.color.primary"
                          clearable
                          clear-icon="mdi-close-circle"
                          rows="12"
                          no-resize
                      ></v-textarea>
                      </div>
                      <div v-else>
                        <v-file-input
                            v-model="$v.form.file.$model"
                            :error-messages="getErrors('form.file')"
                            show-size
                            :accept="`.${form.type}`"
                            label="Файл"
                        ></v-file-input>
                      </div> 

                      <!-- <v-checkbox
                        v-model="form.isValidate"
                        label="Проверять входные данные"
                      ></v-checkbox> -->

                      <!-- <v-checkbox
                        v-model="form.isReplace"
                        label="Заменять задание(я), а не создавать новое(ые)"
                      ></v-checkbox> -->

                      <v-text-field 
                          v-model.trim="form.tag"
                          :error-messages="getErrors('form.tag')"
                          label="Метка загрузки" 
                          name="tag" 
                          type="text" 
                          placeholder=" "
                          :color="$const.color.primary" 
                      ></v-text-field>
                      
                      <template v-if="serverErrors && serverErrors.summary && serverErrors.summary.data" >
                          <v-alert
                            v-for="(errorText, errorKey) in serverErrors.summary.data.notFound"
                            dense
                            type="error"
                            :key="'not-found-' + errorKey"
                          >
                              {{ errorText }}
                          </v-alert>
                          <v-alert
                            v-for="(errorText, errorKey) in serverErrors.summary.data.notFilled"
                            dense
                            type="warning"
                            :key="'not-filled-' + errorKey"
                          >
                              {{ errorText }}
                          </v-alert>
                      </template>

                      <v-divider class="my-4" />

                      <v-btn 
                        class="mr-2"
                        dark 
                        :color="$const.color.primary" 
                        :loading="waiting" 
                        @click.prevent.stop="step--"
                      >
                          {{ prevButtonLabel }}
                      </v-btn>

                      <v-btn dark :color="$const.color.primary" :loading="waiting" @click.prevent.stop="submitStep">
                          {{ buttonLabel }}
                      </v-btn>
                  </div>
                  <!-- END Code activation form -->
              </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'TaskUploadForm',
    props: {
        model: {
            type: Object
        },
        prevButtonLabel: {
            type: String,
            default: 'Назад'
        },
        nextButtonLabel: {
            type: String,
            default: 'Далее'
        },
        lastButtonLabel: {
            type: String,
            default: 'Завершить'
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            step: 1,
            types: [{
              text: 'Вставить JSON в форме',
              value: 'plain'
            },
            {
              text: 'Загрузить JSON-файл',
              value: 'json'
            },
            {
              text: 'Загрузить zip-архив с JSON-файлами',
              value: 'zip'
            }],
            form: {
                type: 'plain',
                json: null,
                file: null,
                tag: '',
                isValidate: false,
                isReplace: true
            },
            waiting: false
        };
    },
    computed: {
        lastStep() {
            return this.step === 2;
        },
        buttonLabel() {
            return this.step === this.lastStep ? this.lastButtonLabel : this.nextButtonLabel;
        }
    },
    validations() {
        return {
            form: {
                type: { required },
                json: {
                  required: function(value) {
                    return this.form.type === 'plain' ? required(value) : true;
                  }
                },
                file: {
                  required: function(value) {
                    return _.includes(['zip', 'json'], this.form.type) ? !_.isNil(value) : true;
                  }
                },
                tag: { required }
            }
        }
    },
    methods: {
        async submitStep() {
            if(!this.lastStep)
            {
              this.step++
              return;
            }

            if (this.validate()) {
                this.waiting = true;
                this.serverErrors = null;

                let form = new FormData();
                _.forOwn(this.form, (v,k) => {
                    form.append(k, v);
                })

                const { success, error } = await this.$store.dispatch('task/upload', form);

                if(!success)
                    this.serverErrors = error;
                else
                    this.$emit('back', 1);

                this.waiting = false;
                return ;
            }
            else
              console.log('fail validate');
        },
    }
}
</script>
<style scoped lang="scss">

</style>