<template>
    <form-view 
        v-slot="{ onSuccess, onBack }"
        title="Новое задание"
        create
        url-update="/task/update/:id"
        url-back="/task"
    >
        <task-upload-form @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import TaskUploadForm from '@/components/forms/TaskUploadForm.vue'
export default {
    name: 'GroupCreate',
    components: { FormView, TaskUploadForm }
}
</script>